<template>
  <div>
    <b-row>
      <b-col style="text-align: center"
        ><b-card><h5>SHAESK</h5></b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col style="text-align: center"
        ><b-card
          ><dx-data-grid
            id="dxDataGrid"
            :data-source="dataSource"
            :word-wrap-enabled="false"
            :remote-operations="false"
            column-resizing-mode="widget"
            :column-min-width="50"
            :show-borders="true"
            :show-row-lines="true"
            :row-alternation-enabled="false"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            height="650px"
          >
            <DxEditing
              :allow-updating="false"
              :allow-adding="false"
              :allow-deleting="false"
              mode="cell"
            ></DxEditing>
            <DxFilterRow :visible="true" />
            <DxFilterPanel :visible="true" />
            <DxFilterBuilderPopup :position="filterBuilderPopupPosition" />
            <DxHeaderFilter :visible="true" />

            <DxPaging :page-size="20" />

            <DxColumnFixing :enabled="true" />

            <DxColumnChooser :enabled="true" />
            <DxGroupPanel :visible="true" />
            <DxGrouping :auto-expand-all="true" />
            <DxExport :enabled="true" :allow-export-selected-data="true" />

            <DxGroupPanel :visible="true" />
            <DxSearchPanel :visible="true" :highlight-case-sensitive="true" />
            <DxGrouping :auto-expand-all="true" />
            <DxPager
              :allowed-page-sizes="pageSizes"
              :show-page-size-selector="true"
            />
            <DxPaging :page-size="100" />
            <dx-column dataField="brand" caption="Marka" />
            <dx-column dataField="name" caption="İsim" />
            <dx-column
              dataField="price"
              caption="Fiyatı(TL)"
              format="#,##0.###"
              data-type="number"
            />
            <dx-column dataField="memory" caption="Bellek" />
            <dx-column dataField="memoryType" caption="Bellek Tipi" />
            <dx-column dataField="chipSet" caption="ChipSet" />

            <dx-column
              caption="Link"
              cell-template="gitTemplate"
              fixed-Position="left"
              :fixed="true"
            />

            <template #gitTemplate="{ data }">
              <b-row>
                <b-col>
                  <b-button
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Düzenle"
                    @click="send(data.data.link)"
                    size="sm"
                    variant="warning"
                    block
                  >
                    <feather-icon icon="SendIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </template> </dx-data-grid
        ></b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BLink, BButton } from "bootstrap-vue";
import {
  DxDataGrid,
  DxColumn,
  DxExport,
  DxSelection,
  DxGroupPanel,
  DxGrouping,
  DxFilterRow,
  DxLoadPanel,
  DxMasterDetail,
  DxColumnFixing,
  DxStateStoring,
  DxHeaderFilter,
  DxFilterPanel,
  DxColumnChooser,
  DxPager,
  DxEditing,
  DxPopup,
  DxLookup,
  DxForm,
  DxPaging,
  DxSearchPanel,
  DxFilterBuilderPopup,
  DxScrolling,
  DxRequiredRule,
  DxEmailRule,
  DxPatternRule,
  DxAsyncRule,
} from "devextreme-vue/data-grid";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import axios from "axios";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
    DxDataGrid,
    DxColumn,
    DxExport,
    DxSelection,
    DxGroupPanel,
    DxGrouping,
    DxFilterRow,
    DxLoadPanel,
    DxMasterDetail,
    DxColumnFixing,
    DxStateStoring,
    DxHeaderFilter,
    DxFilterPanel,
    DxColumnChooser,
    DxPager,
    DxEditing,
    DxPopup,
    DxLookup,
    DxForm,
    DxPaging,
    DxSearchPanel,
    DxFilterBuilderPopup,
    DxScrolling,
    DxRequiredRule,
    DxEmailRule,
    DxPatternRule,
    DxAsyncRule,
    DataSource,
    ArrayStore,
    axios,
    ToastificationContent,
  },
  data() {
    return {
      pageSizes: [100, 200, 500],
      filterBuilderPopupPosition: {
        of: window,
        at: "top",
        my: "top",
        offset: { y: 10 },
      },
      show: false,
      dataSource: new DataSource({
        store: new ArrayStore({
          data: null,
        }),
      }),
    };
  },
  mounted() {
    this.getMethod();
  },
  methods: {
    async send(data) {
      var win = window.open(data, "_blank");
      win.focus();
    },
    async getMethod() {
      this.dataSource = new DataSource({
        store: new ArrayStore({
          data: null,
        }),
      });
      await axios
        .get("https://api.ekrankartifiyatlari.com/api/Products", {
          headers: {
            "content-type": "application/json",
          },
        })
        .then((response) => {
          var cevaplar = response.data;

          this.dataSource = new DataSource({
            store: new ArrayStore({
              data: cevaplar,
            }),
          });
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Başarılı",
                icon: "BellIcon",
                text: "İşlem Başarılı",
                variant: "success",
              },
            },
            {
              position: "bottom-center",
            }
          );
        })
        .catch((err) => {
          console.log(err, "err");
        });
    },
  },
};
</script>

<style></style>
